import { render, staticRenderFns } from "./all-tag.vue?vue&type=template&id=54114994&scoped=true&"
import script from "./all-tag.vue?vue&type=script&lang=ts&"
export * from "./all-tag.vue?vue&type=script&lang=ts&"
import style0 from "./all-tag.vue?vue&type=style&index=0&id=54114994&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54114994",
  null
  
)

export default component.exports