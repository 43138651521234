

































import { getArea, req } from '@/api';
import { defineComponent, onMounted, ref } from '@vue/composition-api';
import type { Ref } from '@vue/composition-api';
interface TagData {
    onlyid: string;
    text: string;
}
export default defineComponent({
    setup() {
        const isLoading: Ref<boolean> = ref(true);
        const tagData: Ref<TagData[]> = ref([]);
        onMounted(() => {
            getArea('BLOG_ARTICLE_TAG_ALL')
                .then(data => (tagData.value = data as TagData[]))
                .catch(error => alert(error))
                .finally(() => (isLoading.value = false));
        });
        return { isLoading, tagData };
    },
});
