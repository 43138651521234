import { defineComponent, onMounted, ref } from '@vue/composition-api';
import type { Ref } from '@vue/composition-api';

import Card from '@/components/Card';

import { Skeleton, Empty } from '@/components/UI';

import AllTag from './all-tag';
import { getArea, req } from '@/api';

import { parseTime } from '@/public/methods';
import store from '@/store';

interface TagArticleData {
	onlyid: string;
	title: string;
	category: string;
	time: number;
}
export default defineComponent({
	components: { Card, Skeleton, Empty, AllTag },
	setup(props: any, { root }: any) {
		const { $route, $message } = root;
		const isShowAll: Ref<boolean> = ref(false);
		const isLoading: Ref<boolean> = ref(true);
		const isError: Ref<boolean> = ref(false);
		const tagArticleData: Ref<TagArticleData[]> = ref([
			{
				onlyid: '',
				title: '未知标题占位',
				category: '未知分类',
				time: -1,
			},
		]);
		const tagKeyWord: Ref<string> = ref('未知');
		const errorText: Ref<string> = ref('');
		onMounted(() => {
			//判断$route，改变isShowAll的值
			if ($route?.params?.onlyid) {
				isShowAll.value = false;
				req('BLOG_ARTICLE_TAG', {
					tag: $route?.params?.onlyid || '',
				})
					.then((data: any) => {
						//console.log('获取数据', data);
						tagArticleData.value = data;
						return getArea('BLOG_ARTICLE_TAG_ALL');
					})
					.then((data: any) => {
						//console.log('全部的', data);
						tagKeyWord.value = data?.find((item: any) => item.onlyid === $route?.params?.onlyid)?.text || '未知';
						tagKeyWord.value &&
							tagKeyWord.value !== '未知' &&
							store.commit('changeMetaInfo', {
								title: `${tagKeyWord.value} - ${store.state.metaInfo.title}`,
							});
					})
					.catch(error => {
						//$message.error(error);
						isError.value = true;
						errorText.value = error;
					})
					.finally(() => {
						isLoading.value = false;
					});
			} else {
				isShowAll.value = true;
			}
		});
		return {
			isShowAll,
			isLoading,
			isError,
			tagArticleData,
			parseTime,
			tagKeyWord,
			errorText,
		};
	},
});
